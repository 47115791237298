import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px;
`;

export const StepOne = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    font-size: 24px;
    max-width: 310px;
    text-align: center;
    margin-bottom: 25px;
    color: #4f4f4f;
  }

  strong {
    font-size: 24px;
    font-weight: 500;
    color: #4f4f4f;
    margin: 25px 0;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 18px;
      color: #4f4f4f;
      font-weight: 300;
      max-width: 220px;
      text-align: center;
      margin: 0 auto 25px auto;
    }

    img {
      width: 173px;
      height: 53px;
    }
  }
`;

export const StepTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    font-size: 24px;
    font-weight: 500;
    color: #4f4f4f;
    margin: 0 0 25px 0;
  }

  p {
    font-size: 18px;
    color: #4f4f4f;
    font-weight: 300;
    max-width: 220px;
    text-align: center;
    margin: 0 auto 25px auto;
  }
`;

export const StepThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  strong {
    font-weight: 500;
    font-size: 24px;
    color: #4f4f4f;
    margin: 0 0 25px 0;
    text-align: center;
    max-width: 260px;
  }

  > button {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 174px;
    height: 35px;
    background: #ff5e7b;
    color: #fff;
    border: none;
    border-radius: 10px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 18px;
      color: #4f4f4f;
      font-weight: 300;
      max-width: 220px;
      text-align: center;
      margin: 0 auto 10px auto;
    }

    a {
      margin-top: 10px;
      color: #ff5e7b;
      text-decoration: none;
    }

    img {
      width: 173px;
      height: 53px;
    }

    span {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
`;

export const StepButton = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    background: #ff5e7b;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    color: #4f4f4f;
  }

  img {
    margin-left: 40px;
  }
`;
