import React, { useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { Container, StepButton, StepOne, StepThree, StepTwo } from './styles';

import stepCode from '../../assets/step-code.png';
import appstore from '../../assets/appstore.png';
import googleplay from '../../assets/googleplay.png';
import stepTwo from '../../assets/step-2.png';
import stepThree from '../../assets/step-3.png';
import stepFour from '../../assets/step-4.png';
import stepFive from '../../assets/step-5.png';

const ActiveSteps = () => {
  const [steps, setSteps] = useState(0);

  const nextStep = () => {
    setSteps(steps + 1);

    const el = document.querySelector('html');

    el.scrollTop = 0;
  };

  return (
    <Container>
      {steps === 0 && (
        <StepOne>
          <p>Esse Código QR não está vinculado a nenhuma conta</p>
          <img src={stepCode} alt="" />
          <strong>Passo 1</strong>
          <div>
            <p>Baixe o app na Play Store ou na App Store</p>
            <a
              href="https://play.google.com/store/apps/details?id=solucoesdaweb.qrcare"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googleplay} alt="" />
            </a>
            <a
              href="https://apps.apple.com/br/app/qr-care-escaneie-me/id1595163536"
              target="_blank"
              rel="noreferrer"
            >
              <img src={appstore} alt="" />
            </a>
          </div>
          <StepButton>
            <button type="button" onClick={nextStep}>
              <MdKeyboardArrowRight size={40} color="#fff" />
            </button>
            <span>Próximo</span>
          </StepButton>
        </StepOne>
      )}

      {steps === 1 && (
        <StepTwo>
          <strong>Passo 2</strong>
          <p>Após baixar o app faça o cadastro.</p>
          <img src={stepTwo} alt="" />
          <StepButton>
            <button type="button" onClick={nextStep}>
              <MdKeyboardArrowRight size={40} color="#fff" />
            </button>
            <span>Próximo</span>
          </StepButton>
        </StepTwo>
      )}

      {steps === 2 && (
        <StepTwo>
          <strong>Passo 3</strong>
          <p>
            Após realizar o cadastro, abra o leitor do QR Care, e leia o código
            QR.
          </p>
          <img src={stepThree} alt="" />
          <StepButton>
            <button type="button" onClick={nextStep}>
              <MdKeyboardArrowRight size={40} color="#fff" />
            </button>
            <span>Próximo</span>
          </StepButton>
        </StepTwo>
      )}

      {steps === 3 && (
        <StepTwo>
          <strong>Passo 4</strong>
          <p>Aponte a câmera para o código QR e aguarde a leitura.</p>
          <img src={stepFour} alt="" />
          <StepButton>
            <button type="button" onClick={nextStep}>
              <MdKeyboardArrowRight size={40} color="#fff" />
            </button>
            <span>Próximo</span>
          </StepButton>
        </StepTwo>
      )}

      {steps === 4 && (
        <StepTwo>
          <strong>Passo 5</strong>
          <p>Toque no botão “vincular”.</p>
          <img src={stepFive} alt="" />
          <StepButton>
            <button type="button" onClick={nextStep}>
              <MdKeyboardArrowRight size={40} color="#fff" />
            </button>
            <span>Próximo</span>
          </StepButton>
        </StepTwo>
      )}

      {steps === 5 && (
        <StepThree>
          <strong>Seu código QR foi vinculado com sucesso!</strong>

          <img src={stepCode} alt="" />

          <button type="button" onClick={() => setSteps(0)}>
            Voltar ao ínicio
          </button>

          <div>
            <p>Agora baixe o nosso app e inicie o processo</p>
            <a
              href="https://play.google.com/store/apps/details?id=solucoesdaweb.qrcare"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googleplay} alt="" />
            </a>
            <a
              href="https://apps.apple.com/br/app/qr-care-escaneie-me/id1595163536"
              target="_blank"
              rel="noreferrer"
            >
              <img src={appstore} alt="" />
            </a>

            <span>ou acesse nosso site </span>

            <a
              href="https://qrcartaoweb.com.br/user/qrcare"
              target="_blank"
              rel="noreferrer"
            >
              qrcare.com.br
            </a>
          </div>
        </StepThree>
      )}
    </Container>
  );
};

export default ActiveSteps;
