import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Docs from '../pages/docs';
import Home from '../pages/home';

const Routes = () => (
  <Switch>
    <Route exact path="/:id" component={Home} />
    <Route exact path="/:id/docs" component={Docs} />
  </Switch>
);

export default Routes;
