import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 150px;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100px;
    width: 100px;
  }
`;
