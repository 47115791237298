import React from 'react';

import { MdPets, MdCake } from 'react-icons/md';
import { FaWeightHanging } from 'react-icons/fa';

import {
  ViewUser,
  HeaderCartao,
  Perfil,
  ViewInfo,
  Info,
  Nome,
  Localizacao,
} from './styles';
import user from '../../images/user.png';

const searchIdade = (date) => {
  let idade = 0;
  if (date) {
    const parts = date.split('/');
    const data = new Date(parts[2], parts[1] - 1, parts[0]);
    const anoNasc = data.getFullYear();
    const mesNasc = data.getMonth();
    const diaNasc = data.getDate();
    const anoAtual = new Date().getFullYear();
    const mesAtual = new Date().getMonth();
    const diaAtual = new Date().getDate();
    idade = anoAtual - anoNasc;
    if (mesAtual < mesNasc) {
      idade -= 1;
    } else if (mesAtual === mesNasc) {
      if (diaAtual < diaNasc) {
        idade -= 1;
      }
    }
  }
  return idade;
};

function CardPets({ card }) {
  return (
    <ViewUser>
      <HeaderCartao>
        <Perfil
          src={card.photo_url ? card.photo_url : user}
          resizeMode="center"
        />

        <Nome>{card.name}</Nome>
        <ViewInfo>
          <MdCake size={20} color="#4f4f4f" />
          <Info>
            {searchIdade(card.birthday) === 0
              ? 'Idade não informada'
              : `${searchIdade(card.birthday)} anos`}
          </Info>
        </ViewInfo>

        <ViewInfo>
          <MdPets size={20} color="#4F4F4F" />
          <Info>{card.breed ? card.breed : 'Raça não informada'}</Info>
        </ViewInfo>
        <ViewInfo>
          <FaWeightHanging size={20} color="#4f4f4f" />
          <Info>
            {card.weight ? `${card.weight} kg` : 'Peso não informado'}
          </Info>
        </ViewInfo>
      </HeaderCartao>
      {(!!card.city || !!card.state) && (
        <Localizacao>
          {card.city}
          {!!card.city && !!card.state ? ' - ' : ''}
          {card.state}
        </Localizacao>
      )}
    </ViewUser>
  );
}

export default CardPets;
