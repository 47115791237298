import React from 'react';

import { ViewUser, Perfil, Nome } from './styles';
import user from '../../images/user.png';

function CardBags({ card }) {
  return (
    <ViewUser>
      <Perfil
        src={card.photo_url ? card.photo_url : user}
        resizeMode="center"
      />

      <Nome>{card.name}</Nome>
    </ViewUser>
  );
}

export default CardBags;
