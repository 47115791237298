import React from 'react';

import {
  ViewUser,
  HeaderCartao,
  Perfil,
  InfosCartao,
  Nome,
  Idade,
  TipoSangue,
  Sangue,
  Localizacao,
} from './styles';
import user from '../../images/user.png';

const searchIdade = (date) => {
  let idade = 0;
  if (date) {
    const parts = date.split('/');
    const data = new Date(parts[2], parts[1] - 1, parts[0]);
    const anoNasc = data.getFullYear();
    const mesNasc = data.getMonth();
    const diaNasc = data.getDate();
    const anoAtual = new Date().getFullYear();
    const mesAtual = new Date().getMonth();
    const diaAtual = new Date().getDate();
    idade = anoAtual - anoNasc;
    if (mesAtual < mesNasc) {
      idade -= 1;
    } else if (mesAtual === mesNasc) {
      if (diaAtual < diaNasc) {
        idade -= 1;
      }
    }
  }
  return idade;
};

function CardCare({ card }) {
  return (
    <ViewUser>
      <HeaderCartao>
        <Perfil
          src={card.photo_url ? card.photo_url : user}
          resizeMode="center"
        />
        <InfosCartao>
          <Nome>
            {card.name ? `${card.name} ` : ''}
            {card.surname}
          </Nome>
          <Idade>{`${searchIdade(card.birthday)} anos`}</Idade>
          {!!card.blood_type && (
            <>
              <TipoSangue>Tipo Sanguíneo</TipoSangue>
              <Sangue>{card.blood_type}</Sangue>
            </>
          )}
        </InfosCartao>
      </HeaderCartao>
      {(!!card.city || !!card.state) && (
        <Localizacao>
          {card.city}
          {!!card.city && !!card.state ? ' - ' : ''}
          {card.state}
        </Localizacao>
      )}
    </ViewUser>
  );
}

export default CardCare;
