import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
  IoMdImages,
} from 'react-icons/io';
import {
  Container,
  Header,
  Logo,
  Card,
  Title,
  TextoInfos,
  ViewResponsavel,
  AcoesResponsavel,
  PerfilResponsavel,
  TextoResponsavel,
  ButtonResponsavel,
  InfoResponsavel,
  Documents,
  Nome,
  ImageTag,
  ViewInfos,
  Message,
  ViewMessage,
} from './styles';
import logo from '../../images/logo.png';
import user from '../../images/user.png';
import apiqrcare from '../../services/apiqrcare';
import CardCare from '../../components/CardCare';
import CardPets from '../../components/CardPets';
import CardBags from '../../components/CardBags';
import ActiveSteps from '../../components/ActiveSteps';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer';

const colorsBack = [
  '#f33a05',
  '#f35a05',
  '#f38a05',
  '#f3aa05',
  '#f3ca05',
  '#f3fa05',
];

const Home = () => {
  const [card, setCard] = useState({});
  const [phones, setPhones] = useState([]);
  const [cardCreate, setCardCreate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  async function loadingCard() {
    try {
      await apiqrcare.get(`/care-qrcodes/${id}`).then((response) => {
        const cartao = response.data;
        console.log(cartao);
        if (cartao.is_active) {
          setCard(cartao.care);
          setCardCreate(true);
          if (cartao.care.phones.length !== 0) {
            setPhones([cartao.care.phones[0]]);
          }
          setIsLoading(false);
        } else {
          setCardCreate(false);
          setIsLoading(false);
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadingCard();
  }, []);

  return (
    <Container>
      <Header>
        <a href="https://qrcare.com.br">
          <Logo src={logo} />
        </a>
      </Header>

      {cardCreate && !isLoading && (
        <Card>
          {card.type === 'care' && <CardCare card={card} />}
          {card.type === 'pets' && <CardPets card={card} />}
          {card.type === 'bags' && <CardBags card={card} />}

          {card.type === 'pets' && (
            <ViewMessage>
              <Message>
                Oi! Se eu estiver perdido, me ajude a voltar para casa. Abaixo
                está o contato dos meus responsáveis.
              </Message>
            </ViewMessage>
          )}
          {card.type === 'bags' && (
            <ViewMessage>
              <Message>
                Oi, se você leu este QR Code, é por que achou algo que me
                pertence. Por favor entre em contato para combinarmos a
                devolução
              </Message>
            </ViewMessage>
          )}

          {phones.length !== 0 && <Title>Responsáveis:</Title>}

          {phones.map((item, index) => (
            <ViewResponsavel style={{ backgroundColor: colorsBack[index] }}>
              <PerfilResponsavel
                src={item.photo_url ? item.photo_url : user}
                resizeMode="contain"
              />
              <InfoResponsavel>
                <TextoResponsavel>{item.name}</TextoResponsavel>
                <TextoResponsavel style={{ fontSize: 13 }}>
                  {item.kinship}
                </TextoResponsavel>
              </InfoResponsavel>
              <AcoesResponsavel>
                <a href={`tel:${item.phone}`}>
                  <ButtonResponsavel>
                    <FaPhoneAlt size={23} color="#000" />
                  </ButtonResponsavel>
                </a>
                <a href={`https://api.whatsapp.com/send?phone=55${item.phone}`}>
                  <ButtonResponsavel>
                    <FaWhatsapp size={23} color="#000" />
                  </ButtonResponsavel>
                </a>
              </AcoesResponsavel>
            </ViewResponsavel>
          ))}
          {card.phones.length > 1 && (
            <div
              onClick={() => {
                if (phones.length === 1) {
                  const phoness = [];
                  card.phones.map((item, index) => {
                    if (index < 5) {
                      phoness.push(item);
                    }
                    return 0;
                  });
                  setPhones(phoness);
                } else {
                  setPhones([card.phones[0]]);
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                width: '80%',
                marginTop: 0,
                marginBottom: 7,
              }}
            >
              <Title
                style={{
                  textAlign: 'center',
                  fontSize: 15,
                  marginLeft: 0,
                  fontFamily: 'Roboto-Medium',
                  width: 'auto',
                  marginBottom: -1,
                  marginRight: 5,
                }}
              >
                {phones.length > 1 ? 'Ocultar Contatos' : 'Mais Contatos'}
              </Title>
              {phones.length > 1 ? (
                <IoIosArrowDropupCircle size={22} color="#ff5e7b" />
              ) : (
                <IoIosArrowDropdownCircle size={22} color="#ff5e7b" />
              )}
            </div>
          )}

          {card.docs && (
            <Documents to={`/${id}/docs`}>
              <IoMdImages size={24} color="#fff" />
              <span> Visualizar documentos</span>
            </Documents>
          )}

          {!!card.comorbidities && (
            <>
              <Title>Comorbidades:</Title>
              <TextoInfos>{card.comorbidities}</TextoInfos>
            </>
          )}

          {!!card.medicines && (
            <>
              <Title>Medicação:</Title>
              <TextoInfos>{card.medicines}</TextoInfos>
            </>
          )}

          {!!card.observation && (
            <>
              <Title>Observações:</Title>
              <TextoInfos>{card.observation}</TextoInfos>
            </>
          )}
          {!!card.chip_number && (
            <>
              <Title>Número do Chip:</Title>
              <TextoInfos>{card.chip_number}</TextoInfos>
            </>
          )}
          {(!!card.tag || !!card.tag_photo_url) && card.type === 'bags' && (
            <>
              <Title>Tag:</Title>
              <ViewInfos style={{ alignItems: 'center' }}>
                <ImageTag src={card.tag_photo_url} />
                <Nome>{card.tag}</Nome>
              </ViewInfos>
            </>
          )}
        </Card>
      )}

      {!cardCreate && !isLoading && <ActiveSteps />}

      {isLoading && <Loading />}

      <Footer />
    </Container>
  );
};

export default Home;
