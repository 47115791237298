import React from 'react';

import { Container } from './styles';
import loading from '../../images/loading.svg';

const Loading = () => (
  <Container>
    <div>
      <img src={loading} alt="loading" />
    </div>
  </Container>
);

export default Loading;
