import React, { useEffect, useState } from 'react';

import { IoIosArrowBack, IoIosCloseCircle } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';

import { Container, Header, Documents, Modal } from './styles';
import logo from '../../images/logo.png';
import apiqrcare from '../../services/apiqrcare';

const Docs = () => {
  const [docs, setDocs] = useState([]);
  const [modalDocImage, setModalDockImage] = useState(null);
  const [activeModal, setActiveModal] = useState(false);

  const history = useHistory();
  const { id } = useParams();

  const loadDocs = async () => {
    const response = await apiqrcare.get(`/care-qrcodes/${id}`);

    setDocs(response.data.care.docs);
  };

  useEffect(() => {
    loadDocs();
  }, []);

  function openModalDoc(docURL) {
    window.scrollTo(0, 0);
    setModalDockImage(docURL);
    setActiveModal(true);
  }

  function closeModalDoc() {
    window.scrollTo(0, 0);
    setModalDockImage(null);
    setActiveModal(false);
  }

  return (
    <>
      <Container>
        <Header>
          <IoIosArrowBack
            size={24}
            color="#4f4f4f"
            onClick={() => history.goBack()}
          />
          <div>
            <img src={logo} alt="opa" />
          </div>
        </Header>

        <Documents>
          <h3>Documentos</h3>
          <p>Toque no botão para abrir o documento desejado.</p>
          {!!docs.length && (
            <ul>
              {docs.map((doc) => (
                <li onClick={() => openModalDoc(doc.doc_url)}>{doc.name}</li>
              ))}
            </ul>
          )}
        </Documents>
      </Container>

      {activeModal && (
        <Modal>
          <div>
            <span onClick={closeModalDoc}>
              <IoIosCloseCircle size={22} />
            </span>

            <img src={modalDocImage} alt="doc" />
          </div>
        </Modal>
      )}
    </>
  );
};
export default Docs;
