import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  position: relative;
  padding: 20px 0;

  svg {
    cursor: pointer;
    position: absolute;
    left: 10px;
  }

  > div {
    margin: 0 auto;
    text-align: center;

    img {
      width: 124px;
    }
  }
`;

export const Documents = styled.div`
  text-align: center;

  h3 {
    font-size: 22px;
    font-weight: 400;
    color: #4f4f4f;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    color: #4f4f4f;
    margin-bottom: 30px;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    li {
      list-style: none;
      cursor: pointer;
      background: #ff5e7b;
      border-radius: 15px;
      width: 320px;
      padding: 12px 0;
      color: #fff;
      text-transform: capitalize;
      margin-bottom: 15px;
    }
  }
`;
export const Modal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -105px;
  z-index: 9999;

  > div {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: #fff;
    color: #4f4f4f;
    padding: 10px 20px;
    border-radius: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 102px;
    text-align: center;
  }

  span {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  img {
    margin-top: 50px;
  }
`;
