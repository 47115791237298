import styled from 'styled-components';

const largura = window.innerWidth;
let larguraCerta = 0;
if (largura > 420) {
  larguraCerta = 420;
} else {
  larguraCerta = largura;
}

export const Perfil = styled.img`
  border-radius: ${larguraCerta * 0.35}px;
  width: ${larguraCerta * 0.35}px;
  height: ${larguraCerta * 0.35}px;
  object-fit: cover;
`;

export const ViewUser = styled.div`
  width: ${larguraCerta * 0.9}px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin: 20px 0 10px 0;
  align-items: center;
`;

export const Nome = styled.p`
  display: flex;
  font-size: 15px;
  margin: 10px;
  text-align: center;
  flex-direction: row;
  font-weight: 600;
  color: #4f4f4f;
`;
