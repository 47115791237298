import React from 'react';
import { Container } from './styles';
import logo from '../../assets/by-qrunico.png';

const Footer = () => (
  <Container>
    <a href="https://qrcartaoweb.com.br/user/qrunico">
      <img src={logo} alt="" />
    </a>
  </Container>
);

export default Footer;
