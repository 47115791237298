import { Link } from 'react-router-dom';
import styled from 'styled-components';

const altura = window.innerHeight;
const largura = window.innerWidth;
let larguraCerta = 0;
if (largura > 420) {
  larguraCerta = 420;
} else {
  larguraCerta = largura;
}

export const Container = styled.div`
  height: 100%;
  min-height: ${`${altura}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: ${`${larguraCerta}px`};
  max-width: 420px;
  margin: 0 auto 25px auto;
`;

export const Header = styled.div`
  display: flex;
  width: ${larguraCerta}px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70px;
`;

export const Logo = styled.img`
  height: 30px;
  max-width: ${larguraCerta * 0.6}px;
`;

export const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
`;

export const Perfil = styled.img`
  border-radius: ${larguraCerta * 0.3}px;
  width: ${larguraCerta * 0.3}px;
  height: ${larguraCerta * 0.3}px;
`;

export const ViewUser = styled.div`
  width: ${larguraCerta * 0.9}px;
  border-radius: 15px;
  margin: 20px 0 10px 0;
  align-items: center;
  background-color: #ececec;
`;

export const HeaderCartao = styled.div`
  width: 92%;
  margin: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const InfosCartao = styled.div`
  display: flex;
  flex-direction: column;
  width: ${larguraCerta * 0.5}px;
  align-items: center;
  padding: 5px 0px;
  justify-content: center;
`;

export const Idade = styled.p`
  font-size: 13px;
  text-align: center;
  color: #4f4f4f;
  margin: 5px;
`;

export const TipoSangue = styled.p`
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  margin: 5px;
  color: #4f4f4f;
`;

export const Sangue = styled.p`
  font-size: 22px;
  padding: 6px 25px;
  font-weight: 600;
  border-radius: 20px;
  width: 100px;
  background-color: #ff5e7b;
  text-align: center;
  color: #fff;
`;

export const Localizacao = styled.p`
  font-size: 15px;
  text-align: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 8px;
  background-color: #dbdbdb;
  width: 100%;
  font-weight: 500;
  color: #4f4f4f;
`;

export const Title = styled.p`
  font-size: 13px;
  width: 100%;
  text-align: left;
  width: ${larguraCerta * 0.9}px;
  color: #4f4f4f;
  margin: 0px 0px 5px 30px;
  font-weight: 600;
`;

export const TextoInfos = styled.p`
  font-size: 13px;
  width: 100%;
  text-align: left;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-weight: 400;
  background-color: #f0f0f0;
  width: ${larguraCerta * 0.9}px;
  color: #4f4f4f;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  border-radius: 50px;
  align-items: center;
  flex-direction: row;
  background-color: #ff5e7b;
  padding: 10px 15px;
  justify-content: center;
`;

export const ViewResponsavel = styled.div`
  width: ${larguraCerta * 0.9}px;
  border-radius: 15px;
  display: flex;
  padding: 8px 10px;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ff5e7b;
`;

export const ButtonFooter = styled.button`
  margin: 0px 10px;
`;

export const PerfilResponsavel = styled.img`
  border-radius: ${larguraCerta * 0.14}px;
  width: ${larguraCerta * 0.14}px;
  height: ${larguraCerta * 0.14}px;
  max-width: 70px;
  max-height: 70px;
  object-fit: cover;
`;

export const InfoResponsavel = styled.div`
  margin: 10px 0;
`;

export const AcoesResponsavel = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextoResponsavel = styled.p`
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  color: #fff;
`;

export const ButtonResponsavel = styled.button`
  margin: 0px 5px;
  display: flex;
  background-color: #fff;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-width: 0px;
`;

export const Documents = styled(Link)`
  width: ${larguraCerta * 0.9}px;
  background: rgb(243, 58, 5);
  border-radius: 15px;
  padding: 12px 10px;
  position: relative;
  margin-bottom: 10px;
  text-decoration: none;

  svg {
    position: absolute;
    left: 15px;
  }

  span {
    font-size: 15px;
    color: #fff;
    text-align: center;
    display: block;
  }
`;

export const ImageTag = styled.img`
  border-radius: ${larguraCerta * 0.35}px;
  width: ${larguraCerta * 0.35}px;
  height: ${larguraCerta * 0.35}px;
  object-fit: cover;
`;

export const Nome = styled.p`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: #4f4f4f;
  margin-top: 15px;
`;

export const ViewInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 15px;
  background-color: #f0f0f0;
  width: ${larguraCerta * 0.9}px;
`;

export const Message = styled.p`
  font-size: 14px;
  width: 95%;
  text-align: center;
  font-weight: 400;
  color: #4f4f4f;
`;

export const ViewMessage = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 15px;
  margin: 5px 0 10px 0;
  justify-content: center;
  background-color: #f0f0f0;
  width: ${larguraCerta * 0.9}px;
`;
